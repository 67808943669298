import { createContext, useState } from "react";

import NotificationContextProps from "../../models/NotificationContextProps";

export const NotificationContext = createContext<NotificationContextProps>({
  isOperationSuccessful: false,
  operationResultMessage: "",
  isNotificationVisible: false,
  setIsOperationSuccessful: null,
  setOperationResultMessage: null,
  setIsNotificationVisible: null,
});

export function NotificationContextProvider({ children }: { children: any }) {
  const [isOperationSuccessful, setIsOperationSuccessful] =
    useState<boolean>(false);
  const [operationResultMessage, setOperationResultMessage] =
    useState<string>("");
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false);

  return (
    <NotificationContext.Provider
      value={{
        isOperationSuccessful,
        operationResultMessage,
        isNotificationVisible,
        setIsOperationSuccessful,
        setOperationResultMessage,
        setIsNotificationVisible,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
