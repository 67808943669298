import { createContext } from "react";

import UserContextProps from "~/models/UserContextProps";
import { useUser } from "./UseUser";

export const UserContext = createContext<UserContextProps | null>(null);

export function UserContextProvider({ children }: { children: any }) {
    const { user, login, logout, isSessionFetched } = useUser();

    return (
        <UserContext.Provider value={{ user, login, logout, isSessionFetched }}>
            {children}
        </UserContext.Provider>
    )
}