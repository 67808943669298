export const PROJECT_ID: string = process.env.REACT_APP_APPWRITE_PROJECT_ID || '';
export const APPWRITE_ENDPOINT: string = process.env.REACT_APP_APPWRITE_BACKEND_ENDPOINT || '';
export const APPWRITE_GET_UNVERIFIED_USERS_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_GET_UNVERIFIED_USERS_FUNCTION_ID || '';
export const APPWRITE_VERIFY_USER_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_VERIFY_USER_FUNCTION_ID || '';
export const APPWRITE_DELETE_USER_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_DELETE_USER_FUNCTION_ID || '';
export const APPWRITE_UPDATE_PRODUCT_LIST_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_UPDATE_PRODUCT_LIST_FUNCTION_ID || '';
export const APPWRITE_DATABASE_ID: string = process.env.REACT_APP_APPWRITE_DATABASE_ID || '';
export const APPWRITE_PRODUCTS_TABLE_ID: string = process.env.REACT_APP_APPWRITE_PRODUCTS_TABLE_ID || '';
export const APPWRITE_PRODUCT_IMAGES_TABLE_ID: string = process.env.REACT_APP_APPWRITE_PRODUCT_IMAGES_TABLE_ID || '';
export const APPWRITE_PRODUCT_IMAGE_BUCKET_ID: string = process.env.REACT_APP_APPWRITE_PRODUCT_IMAGE_BUCKET_ID || '';
export const APPWRITE_UPDATE_PRODUCT_IMAGE_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_UPDATE_PRODUCT_IMAGE_FUNCTION_ID || '';
export const APPWRITE_DELETE_PRODUCT_IMAGE_FUNCTION_DI: string = process.env.REACT_APP_APPWRITE_DELETE_PRODUCT_IMAGE_FUNCTION_ID || '';
export const ACCOUNT_VERIFICATION_DATA_TABLE_ID: string = process.env.REACT_APP_APPWRITE_ACCOUNT_VERIFICATION_DATA_TABLE_ID || '';
export const CREATE_OPERATOR_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_CREATE_OPERATOR_FUNCTION_ID || '';
export const GET_OPERATORS_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_GET_OPERATORS_FUNCTION_ID || '';
export const UPDATE_OPERATOR_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_UPDATE_OPERATOR_FUNCTION_ID || '';
export const OPERATOR_CLIENT_TABLE_ID: string = process.env.REACT_APP_APPWRITE_OPERATOR_CLIENT_TABLE_ID || '';
export const GET_USER_DATA_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_GET_USER_DATA_FUNCTION_ID || '';
export const PRODUCT_DISCOUNTS_TABLE_ID: string = process.env.REACT_APP_PRODUCT_DISCOUNTS_TABLE_ID || '';
export const PRODUCT_CATEGORIES_TABLE_ID: string = process.env.REACT_APP_APPWRITE_PRODUCT_CATEGORIES_TABLE_ID || '';
export const PRODUCT_PROVIDERS_TABLE_ID: string = process.env.REACT_APP_APPWRITE_PRODUCT_PROVIDERS_TABLE_ID || '';
export const PRODUCT_OFFER_PHOTOS_BUCKET_ID: string = process.env.REACT_APP_APPWRITE_PRODUCT_OFFER_PHOTOS_BUCKET_ID || '';
export const PRODUCT_OFFER_PHOTOS_GUESTS_BUCKET_ID: string = process.env.REACT_APP_APPWRITE_PRODUCT_OFFER_PHOTOS_GUESTS_BUCKET_ID || '';
export const GENERAL_MAIL_SENDER_FUNCTION_ID: string = process.env.REACT_APP_APPWRITE_GENERAL_MAIL_SENDER_FUNCTION_ID || '';
export const PRODUCT_OFFER_PHOTOS_GOLD_BUCKET_ID: string = process.env.REACT_APP_APPWRITE_PRODUCT_OFFER_PHOTOS_GOLD_BUCKET_ID || '';
export const PRODUCT_OFFER_PHOTOS_PLATINUM_BUCKET_ID: string = process.env.REACT_APP_APPWRITE_PRODUCT_OFFER_PHOTOS_PLATINUM_BUCKET_ID || '';
export const PRODUCT_OFFER_PHOTOS_SILVER_BUCKET_ID: string = process.env.REACT_APP_APPWRITE_PRODUCT_OFFER_PHOTOS_SILVER_BUCKET_ID || '';
export const CONTACT_FORMS_TABLE_ID: string = process.env.REACT_APP_APPWRITE_CONTACT_FORMS_TABLE_ID || '';