import { useState, createContext, useEffect } from "react";

import { Client } from "../../models/Client";
import ClientAdditionalEmailModal from "../../components/modals/ClientAdditionalEmailModal";

interface Props {
    isClientModalOpen: boolean;
    openClientModal: () => void;
    closeClientModal: () => void;
    client: Client | null;
    chooseClient: (client: Client) => void;
};

export const ClientModalContext = createContext<Props>({
    isClientModalOpen: false,
    closeClientModal: () => {},
    openClientModal: () => {},
    client: null,
    chooseClient: (client: Client) => {}
});

export function ClientModalContextProvider({ children }: { children: any }) {
    const [isClientModalOpen, setIsClientModalOpen] = useState<boolean>(false);
    const [client, setClient] = useState<Client | null>(null);

    const openClientModal = (): void => {
        setIsClientModalOpen(true);
    };

    const closeClientModal = (): void => {
        setIsClientModalOpen(false);
    };

    const chooseClient = (client: Client): void => {
        setClient(client);
    };

    return (
        <ClientModalContext.Provider value={{isClientModalOpen, openClientModal, closeClientModal, client, chooseClient}}>
            <ClientAdditionalEmailModal isModalOpen={isClientModalOpen} closeModal={closeClientModal} user={client} />
            {children}
        </ClientModalContext.Provider>
    )
}