import { useEffect, useState } from "react"

import User from "~/models/User"
import { AuthService } from "../../services/auth/AuthService";

export const useUser = () => {
    const [user, setUser] = useState<User | null>(null);
    const [isSessionFetched, setIsSessionFetched] = useState<boolean>(false);

    const login = (newUser: User) => {
        setUser(newUser);
    };

    const logout = async () => {
        await AuthService.deleteSession();
    }

    const getSession = async () => {
        try {
            const response = await AuthService.getSession();

            if(response) {
                const user: User = {
                    id: response.$id,
                    email: response.email
                }
                setUser(user);
            }
        } catch(error) {
            console.log(`Error while trying to get user session: ${error}`);
        } finally {
            setIsSessionFetched(true);
        }
        
    }

    useEffect(() => {
        getSession();
    }, [])

    return {
        user,
        login,
        logout,
        isSessionFetched
    }
}