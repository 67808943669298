import { Client, Account } from "appwrite";
import { APPWRITE_ENDPOINT, PROJECT_ID } from '../../constants/AppwriteConstants';

import AuthForm from "../../models/AuthForm";

async function login(authFormData: AuthForm) {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const accounts = new Account(client);

    try {
        const response = await accounts.createEmailPasswordSession(
            authFormData.email,
            authFormData.password
        );
        const account = await accounts.get();
        client.setJWT(account.$id);
        return {
            ...response,
            ...account
        };
    } catch(error) {
        console.log(`Error while trying to create session: ${error}`);
        await deleteSession();
        return error;
    }
}

async function deleteSession() {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const accounts = new Account(client);
    try {
        console.debug(`delete session....`);
        await accounts.deleteSession(`current`);
    } catch(error) {
        console.log(`Error while trying to delete session: ${error}`);
    }
}

async function getSession() {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const accounts = new Account(client);

    try {
        const response = await accounts.get();
        client.setJWT(response.$id);
        return response;
    } catch(error) {
        console.log(`Error while trying to get current session: ${error}`);
        return null;
    }
}

export const AuthService = {
    login,
    deleteSession,
    getSession
}