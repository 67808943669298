import { ChangeEvent, useEffect, useReducer, useState } from "react";

import { UserService } from "../../services/user/UserService";
import { Client } from "../../models/Client";
import showNotification from "../../util/notification/ShowNotification";

interface ClientEmailState {
    emails: string[];
    isEmailListLoading: boolean;
};

type ClientEmailAction =
    | { type: `SET_EMAILS`; payload: string[] }
    | { type: `SET_IS_EMAIL_LIST_LOADING`; payload: boolean };

const clientEmailReducer = (state: ClientEmailState, action: ClientEmailAction): ClientEmailState => {
    switch (action.type) {
        case `SET_EMAILS`:
            return {
                ...state,
                emails: action.payload
            };
        case `SET_IS_EMAIL_LIST_LOADING`:
            return {
                ...state,
                isEmailListLoading: action.payload
            }
        default:
            return state;
    }
};

const initialState: ClientEmailState = {
    emails: [],
    isEmailListLoading: false
};

interface Props {
    isModalOpen: boolean;
    closeModal: () => void;
    user: Client | null;
}

export default function ClientAdditionalEmailModal(props: Props) {
    const { isModalOpen, closeModal, user } = props;
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
    const [newEmail, setNewEmail] = useState<string>('');
    const [state, dispatch] = useReducer(clientEmailReducer, initialState);

    const changeNewEmail = (event: ChangeEvent<HTMLInputElement>): void => {
        setNewEmail(event.target.value);
    };

    const addEmail = async (): Promise<void> => {
        if (user) {
            const newEmails: string[] = [
                ...state.emails,
                newEmail
            ];

            const { status, message } = await UserService.updateUser(user.id, null, null, null, null, newEmails, null);

            showNotification(
                status === `completed` ? `E-mail-ul a fost adaugat cu succes!` : `A aparut o eroare in urma adaugarii e-mail-ului`,
                status === `completed` ? 0 : 2
            );

            if (status === `completed`) {
                dispatch({ type: `SET_EMAILS`, payload: newEmails });
                setIsFormVisible(false);
            }
        }
    };

    const deleteEmail = async (requiredEmail: string): Promise<void> => {
        if (user) {
            const newEmails: string[] = state.emails.filter((email: string) => email !== requiredEmail);

            const { status, message } = await UserService.updateUser(user.id, null, null, null, null, newEmails, null);

            showNotification(
                status === `completed` ? `E-mail-ul a fost adaugat cu succes!` : `A aparut o eroare in urma adaugarii e-mail-ului`,
                status === `completed` ? 0 : 2
            );

            if (status === `completed`) {
                dispatch({ type: `SET_EMAILS`, payload: newEmails })
            }
        }
    };

    const fetchClientEmails = async () => {
        dispatch({ type: `SET_IS_EMAIL_LIST_LOADING`, payload: true });

        const documents = await UserService.getUsersData([user?.id || '']);


        dispatch({ type: `SET_EMAILS`, payload: documents.length > 0 ? documents[0].additional_emails : [] });
        dispatch({ type: `SET_IS_EMAIL_LIST_LOADING`, payload: false });
    }

    useEffect(() => {
        if (user) {
            fetchClientEmails();
        }
    }, [user]);

    if (user === null) {
        return (<>
            {
                isModalOpen && (

                    <div
                        className="position-absolute w-100 h-100 bg-dark bg-opacity-50 d-flex align-items-center justify-content-center"
                        style={{ zIndex: 3 }}>
                        <div
                            className="border rounded w-50 h-50"
                            style={{ backgroundColor: '#032454' }}>
                            <h3>Niciun utilizator selectat.</h3>
                        </div>
                    </div>
                )
            }
        </>
        )
    }

    if (state.isEmailListLoading) {
        return (
            <div
                className="position-absolute w-100 h-100 bg-dark bg-opacity-50 d-flex align-items-center justify-content-center"
                style={{ zIndex: 3 }}>
                <div
                    className="border rounded w-50 h-50 d-flex flex-column justify-content-center popup-div position-relative"
                    style={{ backgroundColor: '#1D222B' }}>
                    <h3 className="text-center">E-mailurile adiționale ale utilizatorului {user?.name}</h3>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                isModalOpen && (
                    <div
                        className="position-fixed w-100 h-100 bg-dark bg-opacity-50 d-flex align-items-center justify-content-center"
                        style={{ zIndex: 3, overflow: "hidden" }}>
                        <div
                            className="border rounded w-50 h-50 d-flex flex-column justify-content-center popup-div position-relative">
                            <h3 className="text-center">E-mailurile adiționale ale utilizatorului {user?.name}</h3>
                            <table className="w-100">
                                {
                                    state.emails.length > 0 && state.emails.map((email => (
                                        <tr className="w-100 d-flex flex-row justify-content-center mb-3">
                                            <td
                                                className="me-3 rounded w-25 d-flex align-items-center justify-content-center">
                                                <p className="text-lg m-0 p-1">{email}</p>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-danger text-white"
                                                    onClick={() => deleteEmail(email)}>
                                                    <strong>Șterge</strong>
                                                </button>
                                            </td>
                                        </tr>
                                    )))
                                }
                                {
                                    state.emails.length === 0 && <tr className="text-center text-lg">Niciun rezultat.</tr>
                                }
                            </table>
                            {
                                isFormVisible && (
                                    <div className="w-100 d-flex flex-row justify-content-center">
                                        <input
                                            type="text"
                                            onChange={changeNewEmail}
                                            placeholder="E-mail"
                                            className="me-3 mb-3 w-25 p-1 outline-none border-none" />
                                        <button
                                            onClick={addEmail}
                                            className="btn btn-success h-75 text-white mb-3 me-3">
                                            <strong>Adaugă</strong>
                                        </button>
                                        <button
                                            onClick={() => setIsFormVisible(false)}
                                            className="btn btn-secondary h-75 text-white me-3">
                                            <strong>Anulează</strong>
                                        </button>
                                    </div>
                                )
                            }
                            <div className="w-100 d-flex flex-column align-items-center">
                                <button
                                    className="w-25 mb-3 btn btn-primary"
                                    onClick={() => setIsFormVisible(true)}>
                                    <strong>Adaugă e-mail</strong>
                                </button>
                                <button
                                    className="w-25 mt-3 btn btn-secondary"
                                    onClick={() => {
                                        closeModal();
                                        setIsFormVisible(false);
                                    }}>
                                    <strong>Închide</strong>
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}